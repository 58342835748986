import React from 'react';

const Blog = () => {
  return (
    <div>
      <h1>Blog Page</h1>
      <p>Here are some interesting blog posts.</p>
    </div>
  );
};

export default Blog;
