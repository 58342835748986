import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import '../css/FloatingWhatsAppButton.css'; // Ensure you create this CSS file

const FloatingWhatsAppButton = () => {
  const whatsappUrl = `https://wa.me/YOUR_WHATSAPP_NUMBER`; // Replace YOUR_WHATSAPP_NUMBER with your actual number

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="floating-whatsapp-button"
    >
      <FaWhatsapp size={30} />
    </a>
  );
};

export default FloatingWhatsAppButton;
